// Generated by Framer (838580a)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, getPropertyControls, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
import * as sharedStyle from "../css/hgKudGhZ5";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const cycleOrder = ["lWyVRztOt"];

const serializationHash = "framer-JmING"

const variantClassNames = {lWyVRztOt: "framer-v-nkzyw3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({color, height, icon, id, title, width, ...props}) => { return {...props, jTcf_EQ_W: title ?? props.jTcf_EQ_W ?? "Up to 5 project members", pbavLymla: icon ?? props.pbavLymla ?? "Check", T2CsNbFcm: color ?? props.T2CsNbFcm ?? "rgb(0, 0, 0)"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;color?: string;icon?: string;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, T2CsNbFcm, pbavLymla, jTcf_EQ_W, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "lWyVRztOt", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-nkzyw3", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"lWyVRztOt"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-68oigk-container"} layoutDependency={layoutDependency} layoutId={"eRWuqDgKX-container"}><Phosphor color={T2CsNbFcm} height={"100%"} iconSearch={"House"} iconSelection={pbavLymla} id={"eRWuqDgKX"} layoutId={"eRWuqDgKX"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"bold"} width={"100%"}/></motion.div></ComponentViewportProvider><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1dts917"} data-styles-preset={"hgKudGhZ5"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-T2CsNbFcm-PzjKYwnhA))"}}>Up to 5 project members</motion.p></React.Fragment>} className={"framer-12uzk4n"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"W_33gstOH"} style={{"--extracted-r6o4lv": "var(--variable-reference-T2CsNbFcm-PzjKYwnhA)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--variable-reference-T2CsNbFcm-PzjKYwnhA": T2CsNbFcm, opacity: 0.8}} text={jTcf_EQ_W} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-JmING.framer-13mbx0g, .framer-JmING .framer-13mbx0g { display: block; }", ".framer-JmING.framer-nkzyw3 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-JmING .framer-68oigk-container { flex: none; height: 16px; position: relative; width: 16px; }", ".framer-JmING .framer-12uzk4n { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-JmING.framer-nkzyw3 { gap: 0px; } .framer-JmING.framer-nkzyw3 > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-JmING.framer-nkzyw3 > :first-child { margin-left: 0px; } .framer-JmING.framer-nkzyw3 > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 19
 * @framerIntrinsicWidth 175
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"T2CsNbFcm":"color","pbavLymla":"icon","jTcf_EQ_W":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerPzjKYwnhA: React.ComponentType<Props> = withCSS(Component, css, "framer-JmING") as typeof Component;
export default FramerPzjKYwnhA;

FramerPzjKYwnhA.displayName = "Feature List";

FramerPzjKYwnhA.defaultProps = {height: 19, width: 175};

addPropertyControls(FramerPzjKYwnhA, {T2CsNbFcm: {defaultValue: "rgb(0, 0, 0)", title: "Color", type: ControlType.Color}, pbavLymla: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "Check", description: undefined, hidden: undefined, title: "Icon"}, jTcf_EQ_W: {defaultValue: "Up to 5 project members", displayTextArea: true, title: "Title", type: ControlType.String}} as any)

addFonts(FramerPzjKYwnhA, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...PhosphorFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})